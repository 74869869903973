import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { cookies } from '~/cookie';
import { db } from '~/db';
import { auth, getUser } from '~/firebase';
import { getLanguageFromCookie, getLanguageWithDefault } from '~/language';

const initialState = {
  language: getLanguageFromCookie(),
};

export const languageStore = create(
  combine(initialState, (set) => ({
    selectLanguage: async (languageId: string) => {
      const language = getLanguageWithDefault(languageId);

      cookies.set('language', language.id);

      set({ language });

      const user = getUser();

      if (!user) return;

      await db.profile.update({
        id: user.uid,
        data: {
          languageId: language.id,
        },
      });
    },
  }))
);

export function useLanguage() {
  const store = languageStore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) return;

      const profile = await db.profile.get(user.uid);

      store.selectLanguage(profile?.languageId || 'it-IT');
    });

    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line

  return { ...store };
}
