import { defineCopy } from '~/copy';

export default {
  ask: defineCopy({
    default: 'Ask',
    'de-DE': 'Fragen',
    'es-ES': 'Preguntar',
    'it-IT': 'Chiedere',
    'fr-FR': 'Demander',
  }),
};
