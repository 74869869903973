import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useRef } from 'react';
import { useAudioPlayer } from '~/audio/hooks';
import { Slider } from '~/components';
import { cn } from '~/style';

export const AudioPlayer = ({
  src,
  className,
}: {
  src: string | null | undefined;
  className?: string;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const {
    isPlaying,
    toggle,
    play,
    pause,
    setDuration,
    durationFormatted,
    setCurrentTime,
    currentTimeFormatted,
    currentPercentage,
  } = useAudioPlayer();
  const playAnimationRef = useRef<number>(0);

  const repeat = useCallback(() => {
    const currentTime = audioRef.current?.currentTime ?? 0;

    setCurrentTime(currentTime);

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [setCurrentTime]);

  const onLoadedMetadata = () => {
    if (!audioRef.current) return;

    const seconds = audioRef.current.duration;

    setDuration(seconds);
    setCurrentTime(0);
  };

  useEffect(() => {
    if (!audioRef.current) return;

    const seconds = audioRef.current.duration;

    setDuration(seconds);
    setCurrentTime(0);
  }, [audioRef, setDuration, setCurrentTime]);

  const goToPercentage = useCallback(
    (percentage: number) => {
      if (!audioRef.current) return;

      const newTime = (percentage / 100) * audioRef.current.duration;

      audioRef.current.currentTime = newTime;

      play();
    },
    [audioRef, play]
  );

  const resetAudioPosition = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.play();
      playAnimationRef.current = requestAnimationFrame(repeat);
    } else {
      audioRef.current.pause();
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, [isPlaying, audioRef, repeat]);

  useEffect(() => {
    pause();
  }, [src, audioRef, pause]);

  useEffect(() => {
    if (!audioRef.current) return;

    const handleEnded = () => {
      resetAudioPosition();

      setTimeout(pause, 200);
    };

    const audioElement = audioRef.current;

    audioElement.addEventListener('ended', handleEnded);

    return () => {
      audioElement.removeEventListener('ended', handleEnded);
    };
  }, [audioRef, pause]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  if (!src) return null;

  return (
    <div
      className={cn(
        'flex h-24 w-full items-center rounded-2xl px-6 border dark:border-none dark:bg-muted/50',
        className
      )}
    >
      <motion.div
        className="relative mx-auto flex h-10 w-10 shrink-0 cursor-pointer select-none items-center justify-center rounded-full bg-foreground text-white dark:bg-accent dark:text-foreground md:h-14 md:w-14"
        onClick={() => toggle()}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <AnimatePresence>
          {isPlaying && (
            <div className="absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2 md:h-8 md:w-8">
              <motion.div
                className="flex h-full w-full items-center justify-center"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <svg
                  className="size-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!isPlaying && (
            <div className="absolute left-1/2 top-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 md:h-6 md:w-6">
              <motion.div
                className="flex h-full w-full items-center justify-center"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <svg
                  className="size-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                    clipRule="evenodd"
                  />
                </svg>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </motion.div>

      <div className="w-full px-4 md:px-8">
        <Slider
          className="cursor-pointer"
          max={100}
          value={[currentPercentage]}
          onValueChange={(value) => {
            goToPercentage(value[0]);
          }}
        />
      </div>

      <div className="whitespace-nowrap text-xs text-muted-foreground sm:text-sm">
        {currentTimeFormatted} / {durationFormatted}
      </div>

      <audio
        className="hidden"
        controls
        src={src}
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
      />
    </div>
  );
};
