import { LanguageSelector } from '~/language/components';

export const Header = () => {
  return (
    <header className="mx-auto flex h-24 w-full max-w-8xl items-center justify-center px-4 text-center md:h-32 md:px-8 lg:h-44 2xl:px-0">
      <h1 className="text-balance text-2xl font-bold leading-snug sm:text-3xl sm:leading-tight md:text-4xl md:leading-tight">
        Boggi + Contential
      </h1>

      <div className="fixed top-7 z-40 md:top-11 right-4 lg:top-6">
        <LanguageSelector />
      </div>
    </header>
  );
};
