import { defineCopy } from '~/copy';

export default {
  translationServices: defineCopy({
    default: 'Translation services for<br/>Boggi Milano',
    'it-IT': 'Servizi di traduzione per<br/>Boggi Milano',
  }),
  overview: defineCopy({
    default:
      'Contential simplifies translating your text, audio, and video content into multiple languages, integrating with your existing tools to localise both external communications and internal materials.',
    'it-IT':
      'Contential semplifica la traduzione del tuo contenuto testuale, audio e video in più lingue, integrandosi con i tuoi strumenti esistenti per localizzare sia le comunicazioni esterne che i materiali interni.',
  }),
  text: defineCopy({
    default: 'Text',
    'it-IT': 'Testo',
  }),
  audio: defineCopy({
    default: 'Audio',
    'it-IT': 'Audio',
  }),
  video: defineCopy({
    default: 'Video',
    'it-IT': 'Video',
  }),
  total: defineCopy({
    default: 'Total',
    'it-IT': 'Totale',
  }),
  perMonth: defineCopy({
    default: 'Per month',
    'it-IT': 'Al mese',
  }),
  demo: defineCopy({
    default: 'Demo',
    'it-IT': 'Demo',
  }),
  shortVideo: defineCopy({
    default:
      "Here's a short video showing how Contential can add voiceover to existing Boggi content in multiple languages.",
    'it-IT':
      'Ecco un breve video che mostra come Contential può aggiungere un voiceover ai contenuti esistenti di Boggi in più lingue.',
  }),
  wantToLearn: defineCopy({
    default: 'Want to learn more?',
    'it-IT': 'Vuoi saperne di più?',
  }),
  learnCopy: defineCopy({
    default:
      'If you have any additional questions, please feel free to book a meeting.',
    'it-IT': 'Se hai domande aggiuntive, non esitare a prenotare un incontro.',
  }),
  getInTouch: defineCopy({
    default: 'Book a meeting',
    'it-IT': 'Prenota un incontro',
  }),
  transcript: defineCopy({
    default: 'Video transcript',
    'it-IT': 'Trascrizione video',
  }),
  transcriptCopy: defineCopy({
    default:
      "This summer, step into style with Boggi's 2024 collection. Discover a perfect blend of sophistication and comfort, where bold Mediterranean colours meet lightweight, breathable fabrics. Whether it’s a weekend getaway or an evening in the city, Boggi has you covered with effortlessly elegant pieces designed for every occasion. Elevate your wardrobe and embrace the essence of a timeless summer. Available now in stores and online.",
    'it-IT':
      "Quest'estate, entra nello stile con la collezione Boggi 2024. Scopri il perfetto equilibrio tra sofisticatezza e comfort, dove i vivaci colori mediterranei incontrano tessuti leggeri e traspiranti. Che sia per una fuga del weekend o una serata in città, Boggi ti offre capi elegantemente versatili, pensati per ogni occasione. Eleva il tuo guardaroba e abbraccia l'essenza di un'estate senza tempo. Disponibile ora in negozio e online.",
  }),
  initialSetup: defineCopy({
    default: 'Initial setup',
    'it-IT': 'Configurazione iniziale',
  }),
  oneTimeFee: defineCopy({
    default: 'One-time fee',
    'it-IT': 'Tariffa una tantum',
  }),
};
