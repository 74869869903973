import { defineCopy } from '~/copy';

export default {
  createGroup: defineCopy({
    default: 'Create group',
    'de-DE': 'Gruppe erstellen',
    'es-ES': 'Crear grupo',
    'it-IT': 'Crea gruppo',
    'fr-FR': 'Créer un groupe',
  }),
};
