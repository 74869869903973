import { motion } from 'framer-motion';
import { useState } from 'react';
import { ClickOutside } from '~/components';
import { getLanguages } from '~/language';
import { useLanguage } from '~/language/hooks';
import { cn } from '~/style';

const height = 40;

export const LanguageSelector = ({ className }: { className?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, selectLanguage } = useLanguage();
  const languages = getLanguages({ omit: language.id });

  return (
    <ClickOutside onClickOutside={() => setIsOpen(false)}>
      <div className="relative w-14" style={{ height }}>
        <motion.div
          className={cn(
            'absolute left-0 top-0 z-40 w-14 cursor-pointer select-none overflow-hidden',
            className
          )}
          onClick={() => setIsOpen(!isOpen)}
          initial={{ height }}
          animate={{
            height: isOpen ? (languages.length + 1) * height : height,
          }}
        >
          <div
            className="flex w-full items-center justify-center"
            style={{ height }}
          >
            <img
              className="w-10 rounded-sm"
              src={`/assets/flags/${language.flag}.svg`}
              alt={language.name}
            />
          </div>

          {languages.map((language) => {
            return (
              <motion.div
                className="flex w-full items-center justify-center"
                key={language.id}
                onClick={() => {
                  selectLanguage(language.id);
                }}
                style={{ height }}
                initial={{ opacity: 0.4 }}
                whileHover={{ opacity: 1 }}
              >
                <img
                  className="w-8 rounded-sm"
                  src={`/assets/flags/${language.flag}.svg`}
                  alt={language.name}
                />
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </ClickOutside>
  );
};
