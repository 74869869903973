import { defineCopy } from '~/copy';

export default {
  notifications: defineCopy({
    default: 'Notifications',
    'de-DE': 'Benachrichtigungen',
    'es-ES': 'Notificaciones',
    'it-IT': 'Notifiche',
    'fr-FR': 'Notifications',
  }),
  notification: defineCopy({
    default: 'Notification',
    'de-DE': 'Benachrichtigung',
    'es-ES': 'Notificación',
    'it-IT': 'Notifica',
    'fr-FR': 'Notification',
  }),
  noNotificationsSent: defineCopy({
    default: 'No notifications have been sent',
    'de-DE': 'Es wurden keine Benachrichtigungen gesendet',
    'es-ES': 'No se han enviado notificaciones',
    'it-IT': 'Nessuna notifica è stata inviata',
    'fr-FR': "Aucune notification n'a été envoyée",
  }),
  notificationSent: defineCopy({
    default: 'Notification sent',
    'de-DE': 'Benachrichtigung gesendet',
    'es-ES': 'Notificación enviada',
    'it-IT': 'Notifica inviata',
    'fr-FR': 'Notification envoyée',
  }),
};
