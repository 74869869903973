export const setup: Record<string, { title: string; description: string }[]> = {
  'en-GB': [
    {
      title: 'Account setup',
      description:
        'Configuring access for all users, including admins, managers, and content creators.',
    },
    {
      title: 'Dedicated support',
      description:
        'Providing hands-on support during the initial weeks after launch.',
    },
    {
      title: 'Training and Onboarding',
      description:
        'Providing training to staff on how to use the platform effectively.',
    },
    {
      title: 'Testing and QA',
      description:
        'Rigorous testing of translation accuracy and voice output quality.',
    },
  ],
  'it-IT': [
    {
      title: "Configurazione dell'account",
      description:
        "Configurazione dell'accesso per tutti gli utenti, inclusi amministratori, manager e creatori di contenuti.",
    },
    {
      title: 'Supporto dedicato',
      description:
        'Fornire supporto pratico durante le prime settimane dopo il lancio.',
    },
    {
      title: 'Formazione e onboarding',
      description:
        'Fornire formazione al personale su come utilizzare la piattaforma in modo efficace.',
    },
    {
      title: 'Test e QA',
      description:
        "Test rigorosi sull'accuratezza delle traduzioni e sulla qualità dell'output vocale.",
    },
  ],
};

export const text: Record<string, { title: string; description: string }[]> = {
  'en-GB': [
    {
      title: 'Multiple languages',
      description:
        'Translate text into over 30 languages, ensuring your content is accessible to audiences around the world, no matter the region or dialect.',
    },
    {
      title: 'Document translation',
      description:
        'Easily translate to a variety of document formats, including PDFs, PowerPoint presentations, Word files, and more, while maintaining formatting and accuracy.',
    },
    {
      title: 'Custom style and tone',
      description:
        'Tailor translations to match your brand’s unique voice and tone, incorporating industry-specific terms and language that resonate with your target audience.',
    },
    {
      title: 'Content integration',
      description:
        'Integrate with your existing content management systems to automatically translate website content, blogs, or articles into multiple languages with ease.',
    },
    {
      title: 'Internal communications',
      description:
        'Translate essential internal communications like emails, policies, memos, onboarding materials, and training documents to keep teams aligned across languages.',
    },
  ],
  'it-IT': [
    {
      title: 'Più lingue',
      description:
        'Traduci il testo in oltre 30 lingue, garantendo che i tuoi contenuti siano accessibili a pubblici di tutto il mondo, indipendentemente dalla regione o dal dialetto.',
    },
    {
      title: 'Traduzione di documenti',
      description:
        'Traduci facilmente in una varietà di formati di documenti, inclusi PDF, presentazioni PowerPoint, file Word e altro, mantenendo la formattazione e l’accuratezza.',
    },
    {
      title: 'Stile e tono personalizzati',
      description:
        'Adatta le traduzioni per rispecchiare la voce e il tono unici del tuo marchio, incorporando termini e linguaggi specifici del settore che risuonano con il tuo pubblico.',
    },
    {
      title: 'Integrazione dei contenuti',
      description:
        'Integra con i tuoi sistemi di gestione dei contenuti esistenti per tradurre automaticamente contenuti web, blog o articoli in più lingue con facilità.',
    },
    {
      title: 'Comunicazioni interne',
      description:
        'Traduci le comunicazioni interne essenziali come email, politiche, memo, materiali di onboarding e documenti di formazione per mantenere allineati i team tra le lingue.',
    },
  ],
};

export const audio: Record<string, { title: string; description: string }[]> = {
  'en-GB': [
    {
      title: 'Text to audio',
      description:
        'Convert text into audio in multiple languages, perfect for creating multilingual training materials, presentations, and promotional content with natural-sounding voiceovers.',
    },
    {
      title: 'Speech to speech',
      description:
        'Translate existing audio recordings into other languages, maintaining the flow and accuracy of the original content, ideal for presentations or interviews.',
    },
    {
      title: 'Custom voice',
      description:
        'Develop a custom, brand-specific voice to use in your audio content, creating a consistent and recognisable sound for your training, promotional, or presentation materials.',
    },
  ],
  'it-IT': [
    {
      title: 'Testo in audio',
      description:
        'Converti il testo in audio in più lingue, perfetto per creare materiali di formazione multilingue, presentazioni e contenuti promozionali con voci fuori campo dal suono naturale.',
    },
    {
      title: 'Audio in audio',
      description:
        'Traduci registrazioni audio esistenti in altre lingue, mantenendo il flusso e l’accuratezza del contenuto originale, ideale per presentazioni o interviste.',
    },
    {
      title: 'Voce personalizzata',
      description:
        'Sviluppa una voce personalizzata e specifica del marchio da utilizzare nei tuoi contenuti audio, creando un suono coerente e riconoscibile per i tuoi materiali di formazione, promozionali o presentazioni.',
    },
  ],
};

export const video: Record<string, { title: string; description: string }[]> = {
  'en-GB': [
    {
      title: 'Multiple languages',
      description:
        'Create and localise video content in multiple languages, allowing you to reach and engage diverse audiences with the same high-quality message.',
    },
    {
      title: 'Subtitles and captions',
      description:
        'Add multilingual subtitles or captions to your videos, ensuring accessibility for all viewers, regardless of language or hearing ability.',
    },
    {
      title: 'Auto-publish',
      description:
        'Automatically publish your localised videos to multiple platforms, such as YouTube, Instagram, and other social media channels, in multiple languages with minimal effort.',
    },
  ],
  'it-IT': [
    {
      title: 'Più lingue',
      description:
        'Crea e localizza contenuti video in più lingue, consentendoti di raggiungere e coinvolgere pubblici diversi con lo stesso messaggio di alta qualità.',
    },
    {
      title: 'Sottotitoli e didascalie',
      description:
        'Aggiungi sottotitoli o didascalie multilingue ai tuoi video, garantendo l’accessibilità per tutti gli spettatori, indipendentemente dalla lingua o dalle capacità uditive.',
    },
    {
      title: 'Pubblicazione automatica',
      description:
        'Pubblica automaticamente i tuoi video localizzati su più piattaforme, come YouTube, Instagram e altri canali di social media, in più lingue con il minimo sforzo.',
    },
  ],
};
