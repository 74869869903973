import { defineCopy } from '~/copy';

export default {
  passwords: defineCopy({
    default: 'Passwords',
    'de-DE': 'Passwörter',
    'es-ES': 'Contraseñas',
    'it-IT': 'Password',
    'fr-FR': 'Mots de passe',
  }),
  password: defineCopy({
    default: 'Password',
    'de-DE': 'Passwort',
    'es-ES': 'Contraseña',
    'it-IT': 'Password',
    'fr-FR': 'Mot de passe',
  }),
  newPassword: defineCopy({
    default: 'New password',
    'de-DE': 'Neues Passwort',
    'es-ES': 'Nueva contraseña',
    'it-IT': 'Nuova password',
    'fr-FR': 'Nouveau mot de passe',
  }),
  passwordName: defineCopy({
    default: 'Password name',
    'de-DE': 'Passwortname',
    'es-ES': 'Nombre de la contraseña',
    'it-IT': 'Nome password',
    'fr-FR': 'Nom du mot de passe',
  }),
  addPassword: defineCopy({
    default: 'Add password',
    'de-DE': 'Passwort hinzufügen',
    'es-ES': 'Agregar contraseña',
    'it-IT': 'Aggiungi password',
    'fr-FR': 'Ajouter un mot de passe',
  }),
  noPasswords: defineCopy({
    default: 'No passwords have been added',
    'de-DE': 'Es wurden keine Passwörter hinzugefügt',
    'es-ES': 'No se han agregado contraseñas',
    'it-IT': 'Non sono state aggiunte password',
    'fr-FR': "Aucun mot de passe n'a été ajouté",
  }),
  editPassword: defineCopy({
    default: 'Edit password',
    'de-DE': 'Passwort bearbeiten',
    'es-ES': 'Editar contraseña',
    'it-IT': 'Modifica password',
    'fr-FR': 'Modifier le mot de passe',
  }),
  deletePassword: defineCopy({
    default: 'Deleting a password is permanent and cannot be undone.',
    'de-DE':
      'Das Löschen eines Passworts ist endgültig und kann nicht rückgängig gemacht werden.',
    'es-ES': 'Eliminar una contraseña es permanente y no se puede deshacer.',
    'it-IT': 'Eliminare una password è permanente e non può essere annullato.',
    'fr-FR':
      "La suppression d'un mot de passe est permanente et ne peut pas être annulée.",
  }),
};
