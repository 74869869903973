import { defineCopy } from '~/copy';

export default {
  updates: defineCopy({
    default: 'Updates',
    'de-DE': 'Aktualisierungen',
    'es-ES': 'Actualizaciones',
    'it-IT': 'Aggiornamenti',
    'fr-FR': 'Mises à jour',
  }),
  addUpdate: defineCopy({
    default: 'Add update',
    'de-DE': 'Neue Aktualisierung',
    'es-ES': 'Nueva actualización',
    'it-IT': 'Aggiungi aggiornamento',
    'fr-FR': 'Ajouter une mise à jour',
  }),
  editUpdate: defineCopy({
    default: 'Edit update',
    'de-DE': 'Aktualisierung bearbeiten',
    'es-ES': 'Editar actualización',
    'it-IT': 'Modifica aggiornamento',
    'fr-FR': 'Modifier la mise à jour',
  }),
  deleteUpdate: defineCopy({
    default: 'Delete update',
    'de-DE': 'Aktualisierung löschen',
    'es-ES': 'Eliminar actualización',
    'it-IT': 'Elimina aggiornamento',
    'fr-FR': 'Supprimer la mise à jour',
  }),
  submitUpdate: defineCopy({
    default: 'Submit update',
    'de-DE': 'Aktualisierung einreichen',
    'es-ES': 'Enviar actualización',
    'it-IT': 'Invia aggiornamento',
    'fr-FR': 'Soumettre la mise à jour',
  }),
  noUpdatesAdded: defineCopy({
    default: 'No updates have been added yet',
    'de-DE': 'Es wurden noch keine Aktualisierungen hinzugefügt',
    'es-ES': 'Aún no se han agregado actualizaciones',
    'it-IT': 'Non sono stati ancora aggiunti aggiornamenti',
    'fr-FR': "Aucune mise à jour n'a été ajoutée",
  }),
  whatIsUpdate: defineCopy({
    default: 'What is the update?',
    'de-DE': 'Was ist die Aktualisierung?',
    'es-ES': '¿Cuál es la actualización?',
    'it-IT': 'Qual è l’aggiornamento?',
    'fr-FR': 'Quelle est la mise à jour ?',
  }),
  enterUpdate: defineCopy({
    default: 'Enter update here',
    'de-DE': 'Geben Sie hier die Aktualisierung ein',
    'es-ES': 'Ingrese la actualización aquí',
    'it-IT': 'Inserisci qui l’aggiornamento',
    'fr-FR': 'Entrez la mise à jour ici',
  }),
  deleteUpdatePermanently: defineCopy({
    default:
      'This action will permanently delete the update. This cannot be undone.',
    'de-DE':
      'Diese Aktion löscht die Aktualisierung dauerhaft. Dies kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esta acción eliminará permanentemente la actualización. Esto no se puede deshacer.',
    'it-IT':
      'Questa azione eliminerà definitivamente l’aggiornamento. Questo non può essere annullato.',
    'fr-FR':
      'Cette action supprimera définitivement la mise à jour. Cela ne peut pas être annulé.',
  }),
};
