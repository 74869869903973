import { z } from 'zod';

export const defaultSchema = {
  id: z.string(),
  created: z.number(),
  updated: z.number(),
};

export const account = z.object({
  ...defaultSchema,
  name: z.string(),
  email: z.string(),
  stripeCustomerId: z.string().optional(),
});

export const accountUser = z.object({
  ...defaultSchema,
  accountId: z.string(),
  userId: z.string(),
});

export const userAccount = z.object({
  ...defaultSchema,
  accountId: z.string(),
  userId: z.string(),
});

export const profile = z.object({
  ...defaultSchema,
  userId: z.string(),
  name: z.string(),
  email: z.string(),
  phone: z.union([z.string(), z.null()]).optional(),
  languageId: z.string().optional(),
});

export const group = z.object({
  ...defaultSchema,
  accountId: z.string(),
  name: z.string(),
  planId: z.string().optional(),
  pronunciations: z.string().optional(),
});

export const groupContact = z.object({
  ...defaultSchema,
  groupId: z.string(),
  contactId: z.string(),
  contact: profile,
  role: z.enum(['owner', 'contact']),
});

export const contactGroup = z.object({
  ...defaultSchema,
  groupId: z.string(),
  contactId: z.string(),
  contact: profile,
  role: z.enum(['owner', 'contact']),
});

export const action = z.object({
  ...defaultSchema,
  userId: z.string(),
  user: profile,
  groupId: z.string(),
  name: z.string(),
  description: z.record(z.string(), z.string()),
  data: z.any().optional(),
});

export const contactInviteCode = z.object({
  ...defaultSchema,
  groupId: z.string(),
  email: z.string(),
});

export const groupContactInvite = z.object({
  ...defaultSchema,
  groupId: z.string(),
  email: z.string(),
});

export const content = z.object({
  ...defaultSchema,
  userId: z.string(),
  groupId: z.string(),
  date: z.number(),
  title: z.string(),
  text: z.string(),
  location: z.string().optional(),
  summary: z.string().optional(),
  isArchived: z.boolean(),
  autoGenerate: z.boolean().optional(),
  textInstructions: z.string().optional(),
  data: z
    .record(
      z.string().regex(/^[a-z]{2}-[A-Z]{2}$/),
      z
        .object({
          title: z.string().optional(),
          text: z.string(),
          location: z.string().optional(),
          summary: z.string().optional(),
          textInstructions: z.string().optional(),
        })
        .optional()
    )
    .optional(),
});

export const contentRecording = z.object({
  ...defaultSchema,
  userId: z.string(),
  groupId: z.string(),
  contentId: z.string(),
  status: z.record(
    z.string().regex(/^[a-z]{2}-[A-Z]{2}$/),
    z.enum(['pending', 'complete', 'error'])
  ),
  text: z.string(),
});

export const contentNotification = z.object({
  ...defaultSchema,
  userId: z.string(),
  groupId: z.string(),
  contentId: z.string(),
  status: z.enum(['pending', 'complete', 'error']),
});

export const contactNotification = z.object({
  ...defaultSchema,
  groupId: z.string(),
  contentId: z.string(),
  notificationId: z.string(),
  contactId: z.string(),
  contact: z.union([profile, z.null()]).optional(),
  status: z.enum(['pending', 'complete', 'error']),
  error: z.union([z.string(), z.null()]).optional(),
  errorRetry: z.number().optional(),
});

export const update = z.object({
  ...defaultSchema,
  userId: z.string(),
  user: profile,
  groupId: z.string(),
  contentId: z.string(),
  text: z.string().optional(),
  data: z
    .record(
      z.string().regex(/^[a-z]{2}-[A-Z]{2}$/),
      z
        .object({
          text: z.string(),
        })
        .optional()
    )
    .optional(),
});

export const password = z.object({
  ...defaultSchema,
  userId: z.string(),
  groupId: z.string(),
  name: z.string(),
  password: z.string(),
  description: z.string().optional(),
});

export const device = z.object({
  ...defaultSchema,
  userId: z.string(),
  app: z.any(),
  notificationToken: z.string(),
  version: z.object({
    text: z.string(),
    major: z.number(),
    minor: z.number(),
    webVersion: z.number(),
  }),
});

export const incident = z.object({
  ...defaultSchema,
  userId: z.string(),
  groupId: z.string(),
  date: z.number(),
  title: z.string(),
  description: z.string(),
  location: z.string().optional(),
  summary: z.string().optional(),
  isResolved: z.boolean(),
  data: z
    .record(
      z.string().regex(/^[a-z]{2}-[A-Z]{2}$/),
      z
        .object({
          title: z.string().optional(),
          description: z.string(),
          location: z.string().optional(),
          summary: z.string().optional(),
        })
        .optional()
    )
    .optional(),
});

export const incidentRecording = z.object({
  ...defaultSchema,
  userId: z.string(),
  groupId: z.string(),
  incidentId: z.string(),
  status: z.record(
    z.string().regex(/^[a-z]{2}-[A-Z]{2}$/),
    z.enum(['pending', 'complete', 'error'])
  ),
  text: z.string(),
});

export const incidentAlert = z.object({
  ...defaultSchema,
  userId: z.string(),
  groupId: z.string(),
  incidentId: z.string(),
  status: z.enum(['pending', 'complete', 'error']),
});

export const contactIncidentAlert = z.object({
  ...defaultSchema,
  groupId: z.string(),
  incidentId: z.string(),
  incidentAlertId: z.string(),
  contactId: z.string(),
  contact: z.union([profile, z.null()]).optional(),
  status: z.enum(['pending', 'complete', 'error']),
  error: z.union([z.string(), z.null()]).optional(),
  errorRetry: z.number().optional(),
});

export const incidentUpdate = z.object({
  ...defaultSchema,
  userId: z.string(),
  user: profile,
  groupId: z.string(),
  incidentId: z.string(),
  text: z.string().optional(),
  data: z
    .record(
      z.string().regex(/^[a-z]{2}-[A-Z]{2}$/),
      z
        .object({
          text: z.string(),
        })
        .optional()
    )
    .optional(),
});

// expanded
export const contactNotificationExpanded = contactNotification.merge(
  z.object({
    contact: profile,
  })
);

export const contactIncidentAlertExpanded = contactIncidentAlert.merge(
  z.object({
    contact: profile,
  })
);
