import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { analytics } from '~/analytics';
import { useAuth } from '~/auth/hooks';
import { auth } from '~/firebase';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { setIsReady, setIsSignedIn, setUser, loadAccount } = useAuth();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setIsSignedIn(true);
        analytics.identify();

        await loadAccount();
      } else {
        setUser(null);
        setIsSignedIn(false);
        analytics.reset();
      }

      setIsReady(true);
    });

    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line

  return children;
};
