import { createClient } from './client';
import type {
  Account,
  AccountUser,
  Action,
  ContactGroup,
  ContactIncidentAlert,
  ContactInviteCode,
  ContactNotification,
  Content,
  ContentNotification,
  ContentRecording,
  Device,
  Group,
  GroupContact,
  GroupContactInvite,
  Incident,
  IncidentAlert,
  IncidentRecording,
  IncidentUpdate,
  Password,
  Profile,
  Update,
} from './types';

export const db = {
  account: createClient<Account>({
    path: 'accounts',
  }),

  accountUser: createClient<AccountUser>({
    path: 'accounts/{accountId}/accountUsers',
  }),

  userAccount: createClient<AccountUser>({
    path: 'users/{userId}/userAccounts',
  }),

  profile: createClient<Profile>({
    path: 'profiles',
  }),

  group: createClient<Group>({
    path: 'groups',
  }),

  groupContact: createClient<GroupContact>({
    path: 'groups/{groupId}/groupContacts',
  }),

  contactGroup: createClient<ContactGroup>({
    path: 'users/{contactId}/contactGroups',
  }),

  content: createClient<Content>({
    path: 'groups/{groupId}/contents',
  }),

  password: createClient<Password>({
    path: 'groups/{groupId}/passwords',
  }),

  action: createClient<Action>({
    path: 'groups/{groupId}/actions',
  }),

  contactInviteCode: createClient<ContactInviteCode>({
    path: 'contactInviteCodes',
  }),

  groupContactInvite: createClient<GroupContactInvite>({
    path: 'groups/{groupId}/groupContactInvites',
  }),

  update: createClient<Update>({
    path: 'groups/{groupId}/contents/{contentId}/updates',
  }),

  contentNotification: createClient<ContentNotification>({
    path: 'groups/{groupId}/contents/{contentId}/notifications',
  }),

  contactNotification: createClient<ContactNotification>({
    path: 'groups/{groupId}/contents/{contentId}/notifications/{notificationId}/contactNotifications',
  }),

  contentRecording: createClient<ContentRecording>({
    path: 'groups/{groupId}/contents/{contentId}/contentRecordings',
  }),

  device: createClient<Device>({
    path: 'users/{userId}/devices',
  }),

  incident: createClient<Incident>({
    path: 'groups/{groupId}/incidents',
  }),

  incidentRecording: createClient<IncidentRecording>({
    path: 'groups/{groupId}/incidents/{incidentId}/incidentRecordings',
  }),

  incidentAlert: createClient<IncidentAlert>({
    path: 'groups/{groupId}/incidents/{incidentId}/incidentAlerts',
  }),

  contactIncidentAlert: createClient<ContactIncidentAlert>({
    path: 'groups/{groupId}/incidents/{incidentId}/incidentAlerts/{incidentAlertId}/contactIncidentAlerts',
  }),

  incidentUpdate: createClient<IncidentUpdate>({
    path: 'groups/{groupId}/incidents/{incidentId}/incidentUpdates',
  }),
};
