import { type User } from 'firebase/auth';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { createAccount } from '~/api';
import { goToRedirectPath } from '~/auth';
import { cookies } from '~/cookie';
import { Account, db } from '~/db';
import {
  createUniqueId,
  getUser,
  signIn,
  signInWithToken,
  signOut,
  signUp,
} from '~/firebase';
import { sentry } from '~/sentry';

const initialState = {
  isReady: false,
  isSignedIn: false,

  user: null as User | null,
  account: null as Account | null,

  name: '',
  password: '',
  email: '',

  isSignUpLoading: false,
  isSignInLoading: false,

  error: '',
};

export const useAuth = create(
  combine(initialState, (set, get) => ({
    setIsReady: (isReady: boolean) => {
      set(() => ({ isReady }));
    },

    setIsSignedIn: (isSignedIn: boolean) => {
      set(() => ({ isSignedIn }));
    },

    setName: (name: string) => {
      set(() => ({ name, error: '' }));
    },

    setEmail: (email: string) => {
      set(() => ({ email, error: '' }));
    },

    setPassword: (password: string) => {
      set(() => ({ password, error: '' }));
    },

    setUser: (user: User | null) => {
      set(() => ({ user }));
    },

    setAccount: (account: Account | null) => {
      set(() => ({ account }));
    },

    loadAccount: async () => {
      const userAccount = await db.userAccount.first();

      if (!userAccount) {
        set(() => ({ account: null }));
        return;
      }

      const account = await db.account.get(userAccount.accountId);

      set(() => ({ account }));
    },

    signIn: async () => {
      try {
        const email = get().email;
        const password = get().password;

        if (!email || !password) return;

        set(() => ({ error: '', isSignInLoading: true }));

        await signIn({ email, password });

        const userAccounts = await db.userAccount.list();
        const userAccount = userAccounts[0];
        const account = await db.account.get(userAccount.accountId);

        if (!account) throw new Error('Account not found');

        cookies.set('auth', createUniqueId());
        cookies.set('account', account.id);

        set(() => ({ account, isSignInLoading: false }));

        goToRedirectPath();
      } catch (error) {
        sentry.captureError(error);

        set(() => ({
          error: 'Invalid details',
          isSignInLoading: false,
        }));
      }
    },

    signInWithToken: async (token: string) => {
      await signInWithToken(token);

      const userAccounts = await db.userAccount.list();
      const userAccount = userAccounts[0];
      const account = await db.account.get(userAccount.accountId);

      if (!account) throw new Error('Account not found');

      cookies.set('auth', createUniqueId());
      cookies.set('account', account.id);

      goToRedirectPath();
    },

    signUp: async () => {
      try {
        const name = get().name;
        const email = get().email;
        const password = get().password;

        if (!email || !name || !password) return;

        set(() => ({ error: '', isSignUpLoading: true }));

        await signUp({ name, email, password });

        const user = getUser();

        if (!user) throw new Error('User not found');

        const { account } = await createAccount({ name, email });

        cookies.set('auth', createUniqueId());
        cookies.set('account', account.id);

        set(() => ({ account }));

        goToRedirectPath();

        setTimeout(() => {
          set(() => ({ isSignUpLoading: false }));
        }, 2000);
      } catch (error: any) {
        let message = 'Error. Please try again.';

        if (error.message.includes('email-already-in-use')) {
          message = 'Email is already in use';
        } else {
          sentry.captureError(error);
        }

        set(() => ({
          error: message,
          isSignUpLoading: false,
        }));
      }
    },

    signOut: async () => {
      localStorage.clear();
      cookies.remove('auth');
      cookies.remove('account');
      cookies.remove('group');
      cookies.remove('language');

      await signOut();

      set(() => ({
        user: null,
        account: null,
      }));

      window.location.href = '/';
    },
  })),
);
