import { initializeApp } from 'firebase/app';
import { isProduction } from '~/utils';

const configDev = {
  apiKey: 'AIzaSyDnXr_J0YsdYwrlGGnNTbOk-E4_pQ62oQM',
  authDomain: 'contential-dev.firebaseapp.com',
  projectId: 'contential-dev',
  storageBucket: 'contential-dev.appspot.com',
  messagingSenderId: '1003761134797',
  appId: '1:1003761134797:web:187baf000e3945ca158d84',
};

const configProd = {
  apiKey: 'AIzaSyA8ZigaUKySZyvza22vaTvl-O5iV86kptI',
  authDomain: 'contential-prod.firebaseapp.com',
  projectId: 'contential-prod',
  storageBucket: 'contential-prod.appspot.com',
  messagingSenderId: '1041297268086',
  appId: '1:1041297268086:web:af460fe492f7d33e2a2e57',
};

const config = isProduction() ? configProd : configDev;

export const firebase = initializeApp(config);
