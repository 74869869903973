import posthog from 'posthog-js';
import { getAnalyticsData, getAnalyticsUser } from '~/analytics';
import { sentry } from '~/sentry';
import { isNotProduction } from '~/utils';

export const analytics = {
  init: () => {
    if (isNotProduction()) return;

    posthog.init('phc_VIyaHeCOHAotlhDOXsqXT6O5IezP4F1sUf72puH4hm7', {
      api_host: 'https://eu.i.posthog.com',
    });
  },

  identify: () => {
    if (isNotProduction()) return;

    const user = getAnalyticsUser();

    if (!user) return;

    posthog.identify(user.id, user);
    sentry.setUser({ id: user.id, email: user.email ?? '' });
  },

  track: async (name: string, data?: Record<string, unknown>) => {
    if (isNotProduction()) {
      console.log('[analytics]', 'track', name, data ?? {});

      return;
    }

    posthog.capture(name, getAnalyticsData(data));
  },

  reset: async () => {
    try {
      if (isNotProduction()) {
        console.log('[analytics]', 'reset');

        return;
      }

      posthog.reset();
      sentry.setUser(null);
    } catch (error) {
      // ignore
    }
  },
};
