import { cookies } from '~/cookie';
import { getUser } from '~/firebase';

export const defaultPath = '/content';

export const setRedirectPath = () => {
  cookies.set('redirect', window.location.pathname);
};

export const getRedirectPath = () => {
  const requestedPath = cookies.get('redirect') || defaultPath;
  const path =
    requestedPath.charAt(0) === '/' ? requestedPath : `/${requestedPath}`;

  return path;
};

export const removeRedirectPath = () => {
  cookies.remove('redirect');
};

export const goToRedirectPath = () => {
  const path = getRedirectPath();

  removeRedirectPath();

  window.location.href = path;
};

export const getAccountId = () => {
  const accountId = cookies.get('account');

  if (!accountId) throw new Error('Account ID not found');

  return accountId;
};

export const getAuthToken = async () => {
  const user = getUser();

  if (!user) return '';

  const token = await user.getIdToken();

  return token;
};
