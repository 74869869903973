import axios from 'axios';
import { z } from 'zod';
import { getAuthToken } from '~/auth';
import { isProduction } from '~/utils';

interface CreateFunctionParams<
  TInputSchema extends z.ZodSchema<any>,
  TOutputSchema extends z.ZodSchema<any>,
> {
  name: string;
  input: TInputSchema;
  output: TOutputSchema;
}

export const createFunction = <
  TInputSchema extends z.ZodSchema<any>,
  TOutputSchema extends z.ZodSchema<any>,
>({
  name,
  input,
}: CreateFunctionParams<TInputSchema, TOutputSchema>) => {
  return async (
    inputData: z.infer<TInputSchema>,
  ): Promise<z.infer<TOutputSchema>> => {
    input.parse(inputData);

    const baseUrl = isProduction()
      ? 'https://api.contential.app'
      : 'http://localhost:5000';
    const url = `${baseUrl}/${name}`;
    const token = await getAuthToken();
    const result = await axios({
      url,
      method: 'POST',
      headers: {
        'contential-token': token,
      },
      data: inputData,
    });

    return result.data;
  };
};
