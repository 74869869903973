import { z } from 'zod';
import { createBackgroundFunction, createFunction } from '~/api';
import * as db from '~/db';

// createAccount
export const createAccountSchema = {
  name: 'createAccount',
  schemaPath: 'src/auth/schema/create-account.ts',
  input: z.object({
    name: z.string(),
    email: z.string(),
  }),
  output: z.object({
    account: db.account,
  }),
};

export const createAccount = createFunction(createAccountSchema);

// createGroup
export const createGroupSchema = {
  name: 'createGroup',
  schemaPath: 'src/group/schema/create-group.ts',
  input: z.object({
    accountId: z.string(),
    name: z.string(),
  }),
  output: z.object({
    group: db.group,
  }),
};

export const createGroup = createFunction(createGroupSchema);

// getPayUrl
export const getPayUrlSchema = {
  name: 'getPayUrl',
  schemaPath: 'src/pay/schema/get-pay-url.ts',
  input: z.object({
    accountId: z.string(),
    planId: z.string(),
  }),
  output: z.object({
    url: z.string(),
  }),
};

export const getPayUrl = createFunction(getPayUrlSchema);

// planCheckout
export const planCheckoutSchema = {
  name: 'planCheckout',
  schemaPath: 'src/plan/schema/plan-checkout.ts',
  input: z.object({
    groupId: z.string(),
    sessionId: z.string(),
  }),
  output: z.object({
    success: z.boolean(),
  }),
};

export const planCheckout = createFunction(planCheckoutSchema);

// initAsk
export const initAskSchema = {
  name: 'initAsk',
  schemaPath: 'src/ask/schema/init-ask.ts',
  input: z.object({
    message: z.string(),
  }),
  output: z.object({
    token: z.string(),
  }),
};

export const initAsk = createFunction(initAskSchema);

// acceptContactInvite
export const acceptContactInviteSchema = {
  name: 'acceptContactInvite',
  schemaPath: 'src/contact/schema/accept-contact-invite.ts',
  input: z.object({
    code: z.string(),
  }),
  output: z.object({
    group: z.union([db.group, z.null()]),
  }),
};

export const acceptContactInvite = createFunction(acceptContactInviteSchema);

// getContactInvite
export const getContactInviteSchema = {
  name: 'getContactInvite',
  schemaPath: 'src/contact/schema/get-contact-invite.ts',
  input: z.object({
    code: z.string(),
  }),
  output: z.object({
    invite: z.union([db.contactInviteCode, z.null()]),
    group: z.union([db.group, z.null()]),
  }),
};

export const getContactInvite = createFunction(getContactInviteSchema);

// inviteContact
export const inviteContactSchema = {
  name: 'inviteContact',
  schemaPath: 'src/contact/schema/invite-contact.ts',
  input: z.object({
    groupId: z.string(),
    email: z.string(),
  }),
  output: z.object({}),
};

export const inviteContact = createFunction(inviteContactSchema);

// removeContact
export const removeContactSchema = {
  name: 'removeContact',
  schemaPath: 'src/contact/schema/remove-contact.ts',
  input: z.object({
    groupId: z.string(),
    contactId: z.string(),
  }),
  output: z.object({
    ok: z.boolean(),
  }),
};

export const removeContact = createFunction(removeContactSchema);

// updateContact
export const updateContactSchema = {
  name: 'updateContact',
  schemaPath: 'src/contact/schema/update-contact.ts',
  input: z.object({
    groupId: z.string(),
    contactId: z.string(),
    phone: z.union([z.string(), z.null()]),
    name: z.string(),
  }),
  output: z.object({
    contact: z.union([db.profile, z.null()]),
  }),
};

export const updateContact = createFunction(updateContactSchema);

// createPassword
export const createPasswordSchema = {
  name: 'createPassword',
  schemaPath: 'src/password/schema/create-password.ts',
  input: z.object({
    groupId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    passwordText: z.string(),
  }),
  output: z.object({}),
} as const;

export const createPassword = createFunction(createPasswordSchema);

// getPassword
export const getPasswordSchema = {
  name: 'getPassword',
  schemaPath: 'src/password/schema/get-password.ts',
  input: z.object({
    groupId: z.string(),
    passwordId: z.string(),
  }),
  output: z.object({
    password: z.union([db.password, z.null()]),
  }),
} as const;

export const getPassword = createFunction(getPasswordSchema);

// getPasswords
export const getPasswordsSchema = {
  name: 'getPasswords',
  schemaPath: 'src/password/schema/get-passwords.ts',
  input: z.object({
    groupId: z.string(),
  }),
  output: z.object({
    passwords: z.array(db.password),
  }),
} as const;

export const getPasswords = createFunction(getPasswordsSchema);

// updatePassword
export const updatePasswordSchema = {
  name: 'updatePassword',
  schemaPath: 'src/password/schema/update-password.ts',
  input: z.object({
    groupId: z.string(),
    passwordId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    passwordText: z.string(),
  }),
  output: z.object({}),
} as const;

export const updatePassword = createFunction(updatePasswordSchema);

// background functions

// onContentUpdate
export const onContentUpdateSchema = {
  name: 'onContentUpdate',
  schemaPath: 'src/content/schema/on-content-update.ts',
  input: z.object({
    groupId: z.string(),
    contentId: z.string(),
  }),
} as const;

export const onContentUpdate = createBackgroundFunction(onContentUpdateSchema);

// processContentNotification
export const processContentNotificationSchema = {
  name: 'processContentNotification',
  schemaPath: 'src/content/schema/process-content-notification.ts',
  input: z.object({
    groupId: z.string(),
    contentId: z.string(),
    notificationId: z.string(),
  }),
} as const;

export const processContentNotification = createBackgroundFunction(processContentNotificationSchema);

// generateContentRecording
export const generateContentRecordingSchema = {
  name: 'generateContentRecording',
  schemaPath: 'src/content/schema/generate-content-recording.ts',
  input: z.object({
    groupId: z.string(),
    contentId: z.string(),
    contentRecordingId: z.string(),
  }),
} as const;

export const generateContentRecording = createBackgroundFunction(generateContentRecordingSchema);

// generateIncidentRecording
export const generateIncidentRecordingSchema = {
  name: 'generateIncidentRecording',
  schemaPath: 'src/incident/schema/generate-incident-recording.ts',
  input: z.object({
    groupId: z.string(),
    incidentId: z.string(),
    incidentRecordingId: z.string(),
  }),
} as const;

export const generateIncidentRecording = createBackgroundFunction(generateIncidentRecordingSchema);

// generateIncidentSummary
export const generateIncidentSummarySchema = {
  name: 'generateIncidentSummary',
  schemaPath: 'src/incident/schema/generate-incident-summary.ts',
  input: z.object({
    groupId: z.string(),
    incidentId: z.string(),
  }),
} as const;

export const generateIncidentSummary = createBackgroundFunction(generateIncidentSummarySchema);

// processIncidentAlert
export const processIncidentAlertSchema = {
  name: 'processIncidentAlert',
  schemaPath: 'src/incident/schema/process-incident-alert.ts',
  input: z.object({
    groupId: z.string(),
    incidentId: z.string(),
    incidentAlertId: z.string(),
  }),
} as const;

export const processIncidentAlert = createBackgroundFunction(processIncidentAlertSchema);
