import { useEffect } from 'react';
import { AudioPlayer } from '~/audio/components';
import { getCloudUrl } from '~/cloudinary';
import { CloudImage } from '~/cloudinary/components';
import { Button, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { audio, setup, text, video } from '~/features';
import { Header, Total } from '~/home/components';
import { useLanguage } from '~/language/hooks';
import { formatText, numberWithCommas } from '~/utils';

const prices = {
  setup: 2500,
  text: 950,
  audio: 850,
  video: 2750,
};

export const HomePage = () => {
  const copy = useCopy();
  const { language } = useLanguage();
  const audioUrl = getCloudUrl(`boggi-contential-overview-${language.id}`);

  useEffect(() => {
    document.title = 'Boggi + Contential';
  }, []);

  return (
    <>
      <Header />

      <main className="py-14 md:py-20">
        <div className="space-y-14 md:space-y-20 lg:space-y-28">
          <section className="relative mx-auto flex max-w-6xl px-8 text-center md:px-0 xl:items-center xl:gap-40">
            <div className="mx-auto max-w-sm space-y-8 md:max-w-xl md:space-y-14">
              <h1
                className="text-balance text-2xl font-bold leading-snug sm:text-3xl sm:leading-tight md:text-4xl md:leading-tight lg:text-5xl lg:leading-tight"
                dangerouslySetInnerHTML={{
                  __html: formatText(copy.get('translationServices')),
                }}
              />
            </div>
          </section>

          <section className="max-w-4xl mx-auto px-6 md:px-20 2xl:px-0">
            <AudioPlayer src={audioUrl} />
          </section>

          <section className="max-w-7xl mx-auto px-6 md:px-20 2xl:px-0">
            <CloudImage
              className="rounded-xl"
              id="boggi-2"
              alt="Boggi + Contential"
            />
          </section>

          <section className="relative mx-auto max-w-xl px-6 md:max-w-6xl md:px-20 2xl:px-0">
            <p className="mx-auto max-w-3xl py-4 text-lg md:py-10 md:text-2xl md:leading-relaxed lg:text-3xl lg:leading-snug xl:max-w-4xl xl:text-4xl xl:leading-snug">
              {copy.get('overview')}
            </p>
          </section>

          <div className="pt-10 md:pt-16">
            <Separator />
          </div>

          <section className="mx-auto max-w-6xl px-8 pb-10 pt-10 md:px-20 md:pb-20 lg:pb-32 xl:px-0">
            <div className="mx-auto max-w-xl">
              <h2 className="mb-4 text-balance text-center text-3xl font-bold leading-normal md:text-4xl lg:text-5xl lg:leading-normal">
                {copy.get('demo')}
              </h2>

              <p className="text-balance text-center text-lg text-muted-foreground md:text-xl md:leading-relaxed">
                {copy.get('shortVideo')}
              </p>
            </div>

            <div className="mt-20">
              <iframe
                className="aspect-video rounded-2xl w-full"
                src="https://www.youtube.com/embed/Tbwav4-z0XM?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3"
                title="Boggi Milano | Contential"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>

            <div className="mt-16 space-y-6 max-w-3xl mx-auto">
              <h3 className="font-semibold text-2xl md:text-3xl">
                {copy.get('transcript')}
              </h3>

              <p className="text-muted-foreground text-lg md:text-xl md:leading-relaxed">
                {copy.get('transcriptCopy')}
              </p>
            </div>
          </section>

          <Separator />

          <section className="mx-auto max-w-5xl px-6">
            <div className="py-10 font-bold text-2xl md:text-3xl lg:text-4xl lg:py-16">
              {copy.get('initialSetup')}
            </div>

            <Separator />

            {setup[language.id].map((feature) => (
              <div key={feature.title}>
                <div className="flex flex-col gap-4 pt-4 md:pt-6 lg:flex-row lg:items-center lg:space-y-0">
                  <div className="text-lg md:text-xl">{feature.title}</div>

                  <div className="max-w-lg text-muted-foreground md:text-lg lg:ml-auto lg:text-xl">
                    {feature.description}
                  </div>
                </div>

                <Separator className="mt-4 md:mt-6" />
              </div>
            ))}

            <div className="pt-10 flex flex-col md:flex-row gap-2 md:items-center md:pt-14 lg:pt-20">
              <div className="font-semibold text-lg md:text-xl">
                {copy.get('total')}
              </div>

              <div className="md:ml-auto md:text-right md:space-y-1">
                <div className="font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                  £
                  {numberWithCommas(
                    prices.setup,
                    0,
                    language.thousandSeparator
                  )}
                </div>

                <div className="text-muted-foreground">
                  {copy.get('oneTimeFee')}
                </div>
              </div>
            </div>
          </section>

          <div className="pt-10 md:pt-16">
            <Separator />
          </div>

          <section className="mx-auto max-w-5xl px-6">
            <div className="py-10 font-bold text-2xl md:text-3xl lg:text-4xl lg:py-16">
              {copy.get('text')}
            </div>

            <Separator />

            {text[language.id].map((feature) => (
              <div key={feature.title}>
                <div className="flex flex-col gap-4 pt-4 md:pt-6 lg:flex-row lg:items-center lg:space-y-0">
                  <div className="text-lg md:text-xl">{feature.title}</div>

                  <div className="max-w-lg text-muted-foreground md:text-lg lg:ml-auto lg:text-xl">
                    {feature.description}
                  </div>
                </div>

                <Separator className="mt-4 md:mt-6" />
              </div>
            ))}

            <Total total={prices.text} />
          </section>

          <div className="pt-10 md:pt-16">
            <Separator />
          </div>

          <section className="mx-auto max-w-5xl px-6">
            <div className="py-10 font-bold text-2xl md:text-3xl lg:text-4xl lg:py-16">
              {copy.get('audio')}
            </div>

            <Separator />

            {audio[language.id].map((feature) => (
              <div key={feature.title}>
                <div className="flex flex-col gap-4 pt-4 md:pt-6 lg:flex-row lg:items-center lg:space-y-0">
                  <div className="text-lg md:text-xl">{feature.title}</div>

                  <div className="max-w-lg text-muted-foreground md:text-lg lg:ml-auto lg:text-xl">
                    {feature.description}
                  </div>
                </div>

                <Separator className="mt-4 md:mt-6" />
              </div>
            ))}

            <Total total={prices.audio} />
          </section>

          <div className="pt-10 md:pt-16">
            <Separator />
          </div>

          <section className="mx-auto max-w-5xl px-6">
            <div className="py-10 font-bold text-2xl md:text-3xl lg:text-4xl lg:py-16">
              {copy.get('video')}
            </div>

            <Separator />

            {video[language.id].map((feature) => (
              <div key={feature.title}>
                <div className="flex flex-col gap-4 pt-4 md:pt-6 lg:flex-row lg:items-center lg:space-y-0">
                  <div className="text-lg md:text-xl">{feature.title}</div>

                  <div className="max-w-lg text-muted-foreground md:text-lg lg:ml-auto lg:text-xl">
                    {feature.description}
                  </div>
                </div>

                <Separator className="mt-4 md:mt-6" />
              </div>
            ))}

            <Total total={prices.video} />
          </section>

          <div className="pt-10 md:pt-16">
            <Separator />
          </div>

          <section className="mx-auto max-w-6xl px-8 pb-10 pt-10 md:px-20 md:pb-20 lg:pb-32 xl:px-0">
            <div className="mx-auto max-w-lg">
              <h2 className="mb-4 text-balance text-center text-2xl font-bold leading-normal md:text-3xl lg:text-4xl lg:leading-normal">
                {copy.get('wantToLearn')}
              </h2>

              <p
                className="text-balance text-center text-lg md:text-xl md:leading-relaxed lg:text-2xl lg:leading-relaxed text-muted-foreground"
                dangerouslySetInnerHTML={{
                  __html: formatText(copy.get('learnCopy')),
                }}
              />
            </div>

            <div className="mt-16 text-center md:mt-16">
              <a
                href="https://calendly.com/contential"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="mx-auto w-40 md:mx-0 md:w-52" size="lg">
                  {copy.get('getInTouch')}
                </Button>
              </a>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
