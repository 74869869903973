import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { ContactGroup, db, Group } from '~/db';
import { getUser } from '~/firebase';
import { getGroupId, removeGroupCookie, setGroupCookie } from '../cookie';

const initialState = {
  isReady: false,
  groupId: getGroupId() as string | null,
  groups: [] as Group[],
  group: null as Group | null,
  contactGroup: null as ContactGroup | null,
};

export const groupStore = create(
  combine(initialState, (set, get) => ({
    setIsReady: (isReady: boolean) => {
      set(() => ({ isReady }));
    },

    setGroup: (group: Group | null) => {
      setGroupCookie(group?.id);

      set(() => ({ group, groupId: group?.id }));
    },

    setGroups: (groups: Group[]) => {
      set(() => ({ groups }));
    },

    isOwner: () => get().contactGroup?.role === 'owner',

    loadGroups: async () => {
      const user = getUser();
      const contactGroups = await db.contactGroup.list({
        data: {
          contactId: user?.uid,
        },
      });
      const allGroups = await Promise.all(
        contactGroups.map(async (contactGroup) => {
          const group = await db.group.get(contactGroup.groupId);

          return group;
        }),
      );
      const groups = allGroups.filter((group) => group !== null);

      set(() => ({ groups }));
    },

    loadFirstGroup: async () => {
      const user = getUser();
      const contactGroup = await db.contactGroup.first({
        contactId: user?.uid,
      });

      if (!contactGroup) {
        removeGroupCookie();
        set(() => ({ group: null, groupId: null }));
        return;
      }

      const group = await db.group.get(contactGroup.groupId);

      setGroupCookie(group?.id);

      set(() => ({ group, groupId: group?.id, contactGroup }));
    },

    loadGroup: async (id?: string | null | undefined) => {
      const groupId = id || get().groupId;

      if (!groupId) return;

      const group = await db.group.get(groupId);

      if (!group) {
        removeGroupCookie();
      }

      set(() => ({ group }));
    },

    hasGroup: () => !!get().group,
  })),
);

export function useGroup() {
  const store = groupStore();

  return { ...store };
}
