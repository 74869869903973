import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../hooks';

export const SelectLanguagePage = ({ languageId }: { languageId: string }) => {
  const { selectLanguage } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    selectLanguage(languageId);

    navigate('/');
  }, []);

  return null;
};
