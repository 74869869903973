import { defineCopy } from '~/copy';

export default {
  contacts: defineCopy({
    default: 'Contacts',
    'de-DE': 'Kontakte',
    'es-ES': 'Contactos',
    'it-IT': 'Contatti',
    'fr-FR': 'Contacts',
  }),
  invites: defineCopy({
    default: 'Invites',
    'de-DE': 'Einladungen',
    'es-ES': 'Invitaciones',
    'it-IT': 'Inviti',
    'fr-FR': 'Invitations',
  }),
  addContact: defineCopy({
    default: 'Add contact',
    'de-DE': 'Kontakt hinzufügen',
    'es-ES': 'Agregar contacto',
    'it-IT': 'Aggiungi contatto',
    'fr-FR': 'Ajouter un contact',
  }),
  editContact: defineCopy({
    default: 'Edit contact',
    'de-DE': 'Kontakt bearbeiten',
    'es-ES': 'Editar contacto',
    'it-IT': 'Modifica contatto',
    'fr-FR': 'Modifier le contact',
  }),
  inviteContact: defineCopy({
    default: 'Invite contact',
    'de-DE': 'Kontakt einladen',
    'es-ES': 'Invitar contacto',
    'it-IT': 'Invita contatto',
    'fr-FR': 'Inviter un contact',
  }),
  newContact: defineCopy({
    default: 'New contact',
    'de-DE': 'Neuer Kontakt',
    'es-ES': 'Nuevo contacto',
    'it-IT': 'Nuovo contatto',
    'fr-FR': 'Nouveau contact',
  }),
  removeContact: defineCopy({
    default: 'Remove contact',
    'de-DE': 'Kontakt entfernen',
    'es-ES': 'Eliminar contacto',
    'it-IT': 'Rimuovi contatto',
    'fr-FR': 'Supprimer le contact',
  }),
  enterTheirEmail: defineCopy({
    default: 'Enter their email address',
    'de-DE': 'Geben Sie ihre E-Mail-Adresse ein',
    'es-ES': 'Ingrese su dirección de correo electrónico',
    'it-IT': 'Inserisci il loro indirizzo email',
    'fr-FR': 'Entrez leur adresse e-mail',
  }),
  deleteContactMessage: defineCopy({
    default:
      'This will permanently remove this contact from the group. This action cannot be undone.',
    'de-DE':
      'Dies entfernt diesen Kontakt dauerhaft aus der Gruppe. Diese Aktion kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esto eliminará permanentemente este contacto de la organización. Esta acción no se puede deshacer.',
    'it-IT':
      'Questo rimuoverà definitivamente questo contatto dal gruppo. Questa azione non può essere annullata.',
    'fr-FR':
      'Cela supprimera définitivement ce contact du groupe. Cette action ne peut pas être annulée.',
  }),
  invalidCode: defineCopy({
    default: 'Invalid code',
    'de-DE': 'Ungültiger Code',
    'es-ES': 'Código inválido',
    'it-IT': 'Codice non valido',
    'fr-FR': 'Code invalide',
  }),
  thisCodeInvalid: defineCopy({
    default: 'This code is invalid or has expired',
    'de-DE': 'Dieser Code ist ungültig oder abgelaufen',
    'es-ES': 'Este código es inválido o ha expirado',
    'it-IT': 'Questo codice non è valido o è scaduto',
    'fr-FR': 'Ce code est invalide ou a expiré',
  }),
  youveBeenInvited: defineCopy({
    default: "You've been invited to join the following group:",
    'de-DE': 'Sie wurden eingeladen, der folgenden Group beizutreten:',
    'es-ES': 'Has sido invitado a unirte a la siguiente organización:',
    'it-IT': 'Sei stato invitato a unirti al seguente gruppo:',
    'fr-FR': 'Vous avez été invité à rejoindre le groupe suivant :',
  }),
  acceptInvite: defineCopy({
    default: 'Accept Invite',
    'de-DE': 'Einladung annehmen',
    'es-ES': 'Aceptar invitación',
    'it-IT': 'Accetta invito',
    'fr-FR': "Accepter l'invitation",
  }),
  done: defineCopy({
    default: 'Done',
    'de-DE': 'Erledigt',
    'es-ES': 'Hecho',
    'it-IT': 'Fatto',
    'fr-FR': 'Terminé',
  }),
};
