import { cookies } from '~/cookie';

export const getGroupId = () => {
  return cookies.get('group') || '';
};

export const setGroupCookie = (groupId: string | null | undefined) => {
  if (groupId) {
    cookies.set('group', groupId);
  } else {
    removeGroupCookie();
  }
};

export const removeGroupCookie = () => {
  cookies.remove('group');
};
