import { useCopy } from '~/copy/hooks';
import { useLanguage } from '~/language/hooks';
import { numberWithCommas } from '~/utils';

export const Total = ({ total }: { total: number }) => {
  const copy = useCopy();
  const { language } = useLanguage();

  return (
    <div className="pt-10 flex flex-col md:flex-row gap-2 md:items-center md:pt-14 lg:pt-20">
      <div className="font-semibold text-lg md:text-xl">
        {copy.get('total')}
      </div>
      <div className="md:ml-auto md:text-right md:space-y-1">
        <div className="font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
          £{numberWithCommas(total, 0, language.thousandSeparator)}
        </div>

        <div className="text-muted-foreground">{copy.get('perMonth')}</div>
      </div>
    </div>
  );
};
