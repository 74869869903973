import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from '~/auth/components';
import { HomePage } from '~/home/components';
import { SelectLanguagePage } from '~/language/components';
import { NotFound } from '~/not-found/components';
import { QueryProvider } from '~/query/components';
import { Theme } from '~/theme/components';

export const App = () => {
  const location = useLocation();

  return (
    <>
      <AnimatePresence mode="wait">
        <QueryProvider>
          <AuthProvider>
            <Theme defaultTheme="system">
              <Routes location={location} key={location.pathname}>
                {/* home */}
                <Route path="/" element={<HomePage />} />

                <Route
                  path="/en"
                  element={<SelectLanguagePage languageId="en-GB" />}
                />
                <Route
                  path="/it"
                  element={<SelectLanguagePage languageId="it-IT" />}
                />

                {/* not found */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Theme>
          </AuthProvider>
        </QueryProvider>
      </AnimatePresence>
    </>
  );
};
