import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { firebase, getUser } from '~/firebase';
import { isNotProduction, isProduction } from '~/utils';

export const storage = getStorage(
  firebase,
  isProduction() ? 'contential-prod.appspot.com' : 'contential-dev.appspot.com',
);

if (isNotProduction()) {
  // connectStorageEmulator(storage, 'localhost', 9199);
}

export const uploadFile = async ({
  path,
  blob,
  metadata,
}: {
  path: string;
  blob: Blob | ArrayBuffer | Uint8Array;
  metadata?: Record<string, string>;
}) => {
  const user = getUser();

  if (!user) throw new Error('User not found');

  const storageRef = ref(storage, path);
  const result = await uploadBytes(storageRef, blob, {
    customMetadata: metadata,
  });

  return result;
};

export const getDownloadUrl = async ({ path }: { path: string }) => {
  try {
    const user = getUser();

    if (!user) throw new Error('User not found');

    const storageRef = ref(storage, path);

    const url = await getDownloadURL(storageRef);

    return url;
  } catch (error) {
    return null;
  }
};

export const base64ToBlob = (base64: string, contentType: string): Blob => {
  const byteCharacters = atob(base64);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
